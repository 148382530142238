<template>
  <div>
    <div class="content">
      <div class="all">
        <div class="SearchBar">
          <el-form
              ref="search"
              :inline="true"
              size="small"
          >
            <el-form-item label="缴费方式:" class="searchItem" >
              <el-select v-model="pageParams.payType" clearable placeholder="请选择" style="width: 150px">
                <el-option
                    v-for="item in DataList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="时间段:" class="searchItem" style="margin-left: 10px">
              <el-date-picker
                  size="mini"
                  clearable
                  style="width: 300px;"
                  v-model="time"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  @change="chooseTime"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="操作人:" class="searchItem" style="margin-left: 10px">
              <el-input v-model="pageParams.operBy" size="mini" placeholder="请输入关键字" style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item label="" class="searchItem" style="margin-left: 10px">
              <el-button type="primary" @click="search" size="mini" >查询</el-button>
              <el-button type="warning" @click="restSearch" size="mini" >重置</el-button>
              <el-button type="primary" @click="printInfo" size="mini" v-if="$anthButtons.getButtonAuth('szmxdy')">打印</el-button>
            </el-form-item>
          </el-form>
          <el-table
              ref="tableData"
              :data="tableData"
              border
              :header-cell-style="{
							color: '#333',
							fontFamily: 'MicrosoftYaHeiUI',
							fontSize: '14px',
							fontWeight: 900,
							background: '#f8f8f9',
						}"
              height="calc(100vh - 300px)"
              :stripe="true"
          >
            <el-table-column
                type="index"
                label="序号"
                align="center"
                width="50"
                fixed="left"
               :index="showIndex"
            >
            </el-table-column>
            <el-table-column
                prop="operBy"
                label="收款人姓名"
                align="center"
                fixed="left"
            >
            </el-table-column>
            <el-table-column
                prop="totalNum"
                label="总数量"
                align="center"
                fixed="left"
            >
            </el-table-column>
            <el-table-column
                prop="totalMoney"
                label="总金额"
                align="center"
                fixed="left"
            >
            </el-table-column>
            <template v-for="(a,p) in tableTitle">
              <el-table-column :label="a" :key="a" align="center" >
                <template v-for="col in tableName">
                  <el-table-column
                      :label="col.name"
                      :key="col.key"
                      align="center"
                  >
                    <template slot-scope="{row}">
                          <span>
                              {{row.detailList[p][col.key]}}
                          </span>
                    </template>
                  </el-table-column>
                </template>
              </el-table-column>
            </template>
          </el-table>
          <div class="total">
            <el-pagination
                background
                layout="total, prev, pager, next,sizes, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="pageParams.current"
                :page-sizes="[10, 30, 50, 100]"
                :page-size="pageParams.size"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  excelSileData,
  selectMaterialPaymentRecord,
  selectMaterialOrderRecordHead,
  selectMaterialPaymentTotal
} from "../../RequestPort/kfOrder";
import {exportMethod} from "../../common/js/exportExcel";
export default {
  name: "statistics",
  data(){
    return{
      pageParams:{
        current:1,
        size: 100,
        payType:'',
        searchStr:'',
        operBy:'',
      },
      total:null,
      tableName:[
        {name:'数量',key:'materialTotalNum'},
        {name:'金额' ,key:'materialTotalMoney'}
      ],
      DataList:[{name:'微信',id:1}, {name:'现金',id:2}],
      time:[],
      tableData:[],
      tableTitle:[]
    }
  },
  mounted() {
    selectMaterialOrderRecordHead().then(res=>{
      this.tableTitle = res.data
      //this.loadList(this.pageParams);
    })
  },
  methods:{
    showIndex(index){
      if(index==this.tableData.length-1){
        return ''
      }else {
        return index+1
      }
    },
    printInfo(){
      if (this.tableData.length>0){
        this.$router.push({ path: '/home/printDetilsupply', query: this.pageParams })
      }else {
        this.$message.error('没有要打印的数据！')
      }
    },
    chooseTime(){
      if(this.time){
        this.pageParams.startTime = this.time[0]
        this.pageParams.endTime = this.time[1]
        this.loadList(this.pageParams)
      }
    },
    loadList(obj){
      selectMaterialPaymentRecord(obj).then(res=>{
        this.$refs.tableData.doLayout()
        this.tableData = res.data.records
        this.total = res.data.total
        selectMaterialPaymentTotal(obj).then(reslult =>{
          this.tableData.push(reslult.data[0])
        })
      })
    },
    handleCurrentChange(current) {
      this.pageParams.current = current;
      this.loadList(this.pageParams);
    },
    handleSizeChange(val) {
      this.pageParams.size = val;
      this.handleCurrentChange(1);
    },
    search() {
      this.pageParams.current = 1;
      this.loadList(this.pageParams);
    },
    restSearch() {
      this.pageParams = {
        payType:'',
        searchStr:'',
        operBy:'',
        current: 1,
        size: 100
      };
      this.time = []
      this.tableData = []
      // this.loadList(this.pageParams);
    },
  }
}
</script>

<style lang="scss" scoped>
.searchBox {
  .searchBoxForm {
    display: flex;
    align-items: center;
  }
}
.tableTool{
  height: 25px;
}
.label {
  color: #909399;
}
.content-item {
  color: #444444;
}
.SearchBar {
  border-radius: 5px;
  margin: 10px;
  padding-top: 10px;
  padding-left: 20px;
  background-color: #ffffff;
}
.all {
  background-color:#f3f4f8;height:auto
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  overflow:auto;
  height: calc(100vh - 120px);
}
.search{
  width: 190px;
}
.total{
  margin-left: 15px;
  margin-top: 10px;
}
.table{
  overflow:auto;
  height: 520px;
}
</style>